import React from 'react'
import { useEffect,useState } from 'react'
import axios from 'axios'
import { ApiUrl,baseUrl } from '../utils/config'
import { Link } from 'react-router-dom'
import ServiceIcon from '../../assets/images/vfx-bg.png';
import sign2 from '../../assets/images/gif/sign-yellow.gif';
// import sign2 from '../assets/images/gif/sign-yellow.gif';
// assets/images/vfx-bg.png
const VfxoutSourcing = ({data}) => {
    const [ServiceList, setServiceList] = useState([]);
    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getServiceListHome`);
                setServiceList(response.data);
            } catch (error) {
                console.error("Error fetching banner data:", error);
            }
        };
        fetchBannerData();
    }, []);
    return (
        <section className="p-150 pb-0 sec-outsourcing">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="inter-text-para text-center">
                            <h2><span>{data?.cms_short_title}</span> <label>{data?.cms_short_title}</label></h2>
                            <p><div dangerouslySetInnerHTML={{ __html: data?.cms_long_title }} /></p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        {/* <div className="vfx-outsource-list">
                            <ul>
                                {ServiceList.map((serv,index)=>{
                                    return (
                                        <li key={index}>
                                            <span>{serv?.Name}</span>
                                            <img src={ServiceIcon} />
                                        </li>
                                    )
                                })}
                            </ul>
                        </div> */}
                        <div className="signa-hm text-center">
                            <img src={sign2} />
                        </div>

                        <div className="text-center mt-5">
                            <div className="btn-know-more main-btn">
                                <Link to="/services">More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VfxoutSourcing