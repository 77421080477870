import React from 'react'
import { useEffect,useState } from 'react'
import { ApiUrl,BaseUrl } from '../utils/config';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import social1 from '../../assets/images/socials/001.png';
// import social2 from '../../assets/images/socials/002.png';
// import social3 from '../../assets/images/socials/003.png';
// import social4 from '../../assets/images/socials/004.png';
const HomeBanner = () => {
    const [BannerData, setBannerData] = useState([]);
    useEffect(() => {
        const fetchBannerData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getBanner`);
                setBannerData(response.data);
            } catch (error) {
                console.error("Error fetching banner data:", error);
            }
        };
        fetchBannerData();
    }, []);
    return (
        <section className="bannerPage position-relative">
            <img src={`${BaseUrl}/Banner-Image/${BannerData.cms_image}`} loading="lazy" width="1920" height="952" alt={BannerData?.cms_image1_alt} className="d-none d-sm-block" />
            <img src={`${BaseUrl}/Banner-Image/${BannerData.cms_mobile_image}`} loading="lazy" width="600" height="600" alt={BannerData?.cms_image2_alt} className="d-block d-sm-none" />
            <div className="container">
                <div className="banner-cont">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <h1 className="slider-title">
                                {BannerData?.cms_short_title} <br />
                                <span><div dangerouslySetInnerHTML={{ __html: BannerData?.cms_short_Sub_title }} /></span>
                            </h1>
                            <p className="slider-text"><div dangerouslySetInnerHTML={{ __html: BannerData?.cms_long_title }} /></p>
                            {/* <div className="social-info">
                                <a href="https://www.linkedin.com/in/lanielyves/" target="_blank" rel="noreferrer"><img src={social1} alt="Social 1"/></a>

                                <a href="javascript:void(0)" target="_blank" rel="noreferrer"><img src={social2} alt='social 2'/></a>

                                <a href="javascript:void(0)" target="_blank" rel="noreferrer"><img src={social2} alt="Social 3"/></a>

                                <a href="https://calendly.com/yves56/virtual-coffee-with-yves" target="_blank"><img src={social4} alt="Social 4"/></a>
                            </div>
                            <div className="btn-read-more">
                                <div className="slider-btn d-none d-lg-block"><i className="fa-solid fa-arrow-left-long"></i> Explore</div>
                                <div className="slider-btn d-block d-lg-none">Explore <i className="fa-solid fa-arrow-right-long"></i></div>
                            </div> */}
                            <Link to={BannerData?.BannerLink}>
                                <div className="btn-know-more main-btn">
                                    <span className="mediaBtn">{BannerData?.BannerButtonName}</span>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default HomeBanner