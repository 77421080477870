import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../Component/utils/config';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
const MediaDetails = () => {
  let { mediaslug } = useParams();
  const [MediaDetails, setMediaDetails] = useState();
  const [MediaList, setMediaList] = useState([]);
  const [headTitleee,setheadTitleee] = useState();
  useEffect(() => {
    const apiUrl = `${ApiUrl}/getmediaDetails/${mediaslug}`;
    axios.get(apiUrl)
      .then((response) => {
        setMediaDetails(response.data.mediaDetails);
        setMediaList(response.data.MediaList);
        setheadTitleee(response.data.HeadTitlee);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, [mediaslug]);
  // **********
  const originalDateStr = MediaDetails?.NewsDate;
  const originalDate = new Date(originalDateStr);
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const monthAbbreviation = months[originalDate.getMonth()];
  const MainformattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
  // *********
  return (
    <>
      <Helmet>
        <title>{MediaDetails?.MetaTitle}</title>
        <meta name="description" content={MediaDetails?.MetaDesctiption} />
        {/* <link rel="canonical" href={MediaDetails?.CanonicalTag} />
          <meta name='robots' content={`${MediaDetails?.IndexValue == 1 ? ', index' : 'noindex'}, ${MediaDetails?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} /> */}
      </Helmet>
      <Header />
      <main className="main-content">

        <section className="inner-page-intro position-relative p-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="inter-text-para media-pg-details">
                  <h1>{MediaDetails?.NewsTitle}</h1>
                  <div className="media-loc-date">
                    <span><i className="fa-solid fa-calendar-days"></i> {MainformattedDate}</span>
                    {MediaDetails?.NewsLocation && (
                      <span><i className="fa-solid fa-location-dot"></i> {MediaDetails?.NewsLocation}</span>
                    )}
                  </div>
                  <div className="media-main-ing">

                    <img src={`${BaseUrl}/blog-image/${MediaDetails?.NewsMainImage}`} />
                  </div>

                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: MediaDetails?.NewsLongDescription }} /></p>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="others-medias media-info d-none d-lg-block">
                  <h2>{headTitleee}</h2>
                  {MediaList.map((RestMedia, index) => {
                    const originalDateStr = RestMedia?.NewsDate;
                    const originalDate = new Date(originalDateStr);
                    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                    const monthAbbreviation = months[originalDate.getMonth()];
                    const formattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
                    return (
                      <div className="others-medias">
                        <Link to={`/media/${RestMedia.MetaUrl}`}>
                          <ul>
                            <li>
                              <img src={`${BaseUrl}/blog-image/${RestMedia?.NewsImage}`} />
                            </li>
                            <li>
                              <div className="intro-page-para">
                                <h3>{RestMedia?.NewsTitle}</h3>
                                <div className="media-loc-date">
                                  <span><i className="fa-solid fa-calendar-days"></i> {formattedDate}</span>
                                  {RestMedia?.NewsLocation && (
                                    <span><i className="fa-solid fa-location-dot"></i> {RestMedia?.NewsLocation}</span>
                                  )}
                                  
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Link>
                      </div>
                    );
                  })}
                </div>

                <div className="others-medias media-info d-block d-lg-none">
                  <h2>{headTitleee}</h2>

                  <Swiper className="swiper mySwiper mediaSlider" loop={true} modules={[Pagination]} pagination={{ clickable: true }}>
                    <div className="swiper-wrapper">
                      {MediaList.map((mobmedia, index) => {
                        const originalDateStr = mobmedia?.NewsDate;
                        const originalDate = new Date(originalDateStr);
                        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                        const monthAbbreviation = months[originalDate.getMonth()];
                        const formattedDate12 = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
                        return (
                          <SwiperSlide className="swiper-slide" key={index}>
                            <div className="others-medias">
                              {/* <a href="javascript:void(0)"> */}
                              <Link to={`/media/${mobmedia.MetaUrl}`}>
                                <ul>
                                  <li>
                                    <img src={`${BaseUrl}/blog-image/${mobmedia?.NewsImage}`} />
                                  </li>
                                  <li>
                                    <div className="intro-page-para">
                                      <h3>{mobmedia?.NewsTitle}</h3>
                                      <div className="media-loc-date">
                                        <span><i className="fa-solid fa-calendar-days"></i> {formattedDate12}</span>
                                        {mobmedia?.NewsLocation && (
                                          <span><i className="fa-solid fa-location-dot"></i> {mobmedia?.NewsLocation}</span>
                                        )}
                                        
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Link>
                            </div>
                          </SwiperSlide>
                        )
                      })}

                    </div>

                    {/* <div className="swiper-pagination"></div> */}
                  </Swiper>
                </div>

              </div>
            </div>
          </div>
        </section>


      </main>
      <Footer />
    </>
  )
}

export default MediaDetails