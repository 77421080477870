import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Filmography from "./Pages/Filmography";
import Home from "./Pages/Home";
import Media from "./Pages/Media";
import MediaDetails from "./Pages/MediaDetails";
import Partners from "./Pages/Partners";
import Team from "./Pages/Team";
import VfxOutsourcing from "./Pages/VfxOutsourcing";
import Blog from "./Pages/Blog";
import BlogDetails from "./Pages/BlogDetails";
import ScrollToTop from './Component/utils/ScrollToTop'
import scrollCircle from "./Component/Common/ScrollCircle";
import Cookies from "./Layout/Cookies";
function App() {
  return (
    <>
      <Router ScrollToTop>
        <ScrollToTop/>
        <div class="wrapper">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<VfxOutsourcing />} />
            <Route path="/founder" element={<About />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/filmography" element={<Filmography />} />
            <Route path="/media" element={<Media />} />
            <Route path="/media/:mediaslug" element={<MediaDetails />} />
            <Route path="/clients" element={<Partners />} />
            <Route path="/team" element={<Team />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/blog/:blogSlug" element={<BlogDetails />} />
          </Routes>
        </div>
        <Cookies/>
      </Router>
    </>
  );
}

export default App;
