import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { ApiUrl, BaseUrl } from '../Component/utils/config'

import VfxIntro from '../Component/VfxComponent/VfxIntro'
import VfxPartner from '../Component/VfxComponent/VfxPartner'

import ServiceIcon from '../assets/images/star.png';
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle'
import { Helmet } from 'react-helmet'
const VfxOutsourcing = () => {
  const [AllVfxData, setAllVfxData] = useState([]);
  const [MainServiceList, setMainServiceList] = useState([]);
  const [SecondaryServiceList, setSecondaryServiceList] = useState([]);
  const [AllPoint, setAllPoint] = useState([]);
  useEffect(() => {
    const fetchVFXdata = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getAllVfxData`);
        setAllVfxData(response.data);
        setAllPoint(response.data.AllPoints);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    fetchVFXdata();
  }, []);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getServiceList`);
        // setServiceList(response.data);
        setMainServiceList(response.data.MainServiceList);
        setSecondaryServiceList(response.data.SecondaryServiceList);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBannerData();
  }, []);
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{AllVfxData.VfxIntroDet?.cms_meta_title}</title>
        <meta name="description" content={AllVfxData.VfxIntroDet?.cms_meta_description} />
        <link rel="canonical" href={AllVfxData.VfxIntroDet?.CanonicalTag} />
        <meta name='robots' content={`${AllVfxData.VfxIntroDet?.IndexValue == 1 ? ', index' : 'noindex'}, ${AllVfxData.VfxIntroDet?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
        {/* <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' /> */}
      </Helmet>
      <Header />
      <main className="main-content">
        <VfxIntro data={AllVfxData.VfxIntroDet} />
        {/* <VfxPartner data={AllVfxData.VfxPartnerDet} /> */}
        <section className="p-100 pt-0 position-relative sub-section">
          
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="inter-text-para">
                  <h2><span>{AllVfxData.VfxServicesDet?.cms_short_title}<br />{AllVfxData.VfxServicesDet?.cms_short_Sub_title}</span></h2>
                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: AllVfxData.VfxServicesDet?.cms_long_title }} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className='container'>
            <div className="row ">
              <div className="col-lg-6">

                <div className="vfx-outsource-list pg-service">
                  <div className="inter-text-para">
                    <h3>VFX EXPERTISE AVAILABLE</h3>
                  </div>
                  <ul>
                    {MainServiceList.map((Serv, index) => {
                      return (
                        <li key={index}>
                          <span>{Serv?.Name}</span>
                          <img src={ServiceIcon} />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>

              <div className="col-lg-6">

                <div className="why-me-points">
                  <div className="inter-text-para">
                    {/* <h3>END-TO-END SUPPORT</h3> */}
                    <h3>RELIABLE EXECUTIVE SUPPORT</h3>
                  </div>
                  <ul>
                    {SecondaryServiceList.map((point, index) => {
                      return (
                        <li className='EndToEnd' key={index}>
                          {/* <i className="fa-solid fa-arrow-right"></i> */}
                          <i className="fa-solid fa fa-star" aria-hidden="true"></i>
                          <p><span>{point?.Name}</span> </p>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="p-100 position-relative sub-section iner-serv-partners" style={{ background: '#1c1c1c' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="inter-text-para">
                  <h2><span>{AllVfxData.VfxSecondServicess?.cms_short_title}<br />{AllVfxData.VfxSecondServicess?.cms_short_Sub_title}</span></h2>
                  <div dangerouslySetInnerHTML={{ __html: AllVfxData.VfxSecondServicess?.cms_long_title }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="why-me-points">
                  <ul>
                    {AllPoint.map((point, index) => {
                      return (
                        <li>
                          {/* <i className="fa-solid fa-arrow-right"></i> */}
                          <i className="fa-solid fa fa-star" aria-hidden="true"></i>
                          <p><span>{point?.PolicyName}</span> {point?.PolicyDescription}</p>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ScrollCircle />
      <Footer />
    </>
  )
}

export default VfxOutsourcing