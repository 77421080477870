import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../Component/utils/config';
const BlogDetails = () => {
  let { blogSlug } = useParams();
  const [BlogDetails, setBlogDetails] = useState();
  const [BlogList, setBlogList] = useState([]);
  useEffect(() => {
    const apiUrl = `${ApiUrl}/getblogDetails/${blogSlug}`;
    axios.get(apiUrl)
      .then((response) => {
        setBlogDetails(response.data.blogDetails);
        setBlogList(response.data.BlogList);
         window.location.reload();
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, [blogSlug]);
  // **********
  const originalDateStr = BlogDetails?.NewsDate;
  const originalDate = new Date(originalDateStr);
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const monthAbbreviation = months[originalDate.getMonth()];
  const MainformattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
  // *********
  return (
    <>
      <Header />
      <main className="main-content">

        <section className="inner-page-intro position-relative p-150 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="inter-text-para media-pg-details">
                  <h1>{BlogDetails?.NewsTitle}</h1>
                  <div className="media-loc-date">
                    <span><i className="fa-solid fa-calendar-days"></i> {MainformattedDate}</span>
                    <span><i className="fa-solid fa-location-dot"></i> {BlogDetails?.NewsLocation}</span>
                  </div>
                  <div className="media-main-ing">

                    <img src={`${BaseUrl}/blog-image/${BlogDetails?.NewsMainImage}`} />
                  </div>

                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: BlogDetails?.NewsLongDescription }} /></p>
                  </div>
                </div>
              </div>

              <div className="col-lg-5">
                <div className="others-medias media-info d-none d-lg-block">
                  <h2>Latest Media</h2>
                  {BlogList.map((RestMedia, index) => {
                    const originalDateStr = RestMedia?.NewsDate;
                    const originalDate = new Date(originalDateStr);
                    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                    const monthAbbreviation = months[originalDate.getMonth()];
                    const formattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
                    return (
                      <div className="others-medias">
                        <Link to={`/blog/${RestMedia.MetaUrl}`}>
                          <ul>
                            <li>
                              <img src={`${BaseUrl}/blog-image/${RestMedia?.NewsImage}`} />
                            </li>
                            <li>
                              <div className="intro-page-para">
                                <h3>{RestMedia?.NewsTitle}</h3>
                                <div className="media-loc-date">
                                  <span><i className="fa-solid fa-calendar-days"></i> {formattedDate}</span>
                                  <span><i className="fa-solid fa-location-dot"></i> {RestMedia?.NewsLocation}</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Link>
                      </div>
                    );
                  })}
                </div>

                <div className="others-medias media-info d-block d-lg-none">
                  <h2>Latest Media</h2>

                  <div className="swiper mySwiper mediaSlider">
                    <div className="swiper-wrapper">
                      {BlogList.map((mobmedia, index) => {
                        const originalDateStr = mobmedia?.NewsDate;
                        const originalDate = new Date(originalDateStr);
                        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                        const monthAbbreviation = months[originalDate.getMonth()];
                        const formattedDate12 = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
                        return (
                          <div className="swiper-slide" key={index}>
                            <div className="others-medias">
                            <Link to={`/blog/${mobmedia.MetaUrl}`}>
                                <ul>
                                  <li>
                                    <img src={`${BaseUrl}/blog-image/${mobmedia?.NewsImage}`} />
                                  </li>
                                  <li>
                                    <div className="intro-page-para">
                                      <h3>{mobmedia?.NewsTitle}</h3>
                                      <div className="media-loc-date">
                                        <span><i className="fa-solid fa-calendar-days"></i> {formattedDate12}</span>
                                        <span><i className="fa-solid fa-location-dot"></i> {mobmedia?.NewsLocation}</span>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Link>
                            </div>
                          </div>
                        )
                      })}

                    </div>

                    {/* <div className="swiper-pagination"></div> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>


      </main>
      <Footer />
    </>
  )
}

export default BlogDetails