import React from 'react'

const ScrollCircle = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 100 100" class="progress-wheel">
            <circle cx="50" cy="50" r="30" pathLength="1" class="bg" />
            <circle cx="50" cy="50" r="30" pathLength="1" class="progress" />
        </svg>
        // <div>
        //     <span style={{color:'white'}}>Souvik</span>
        // </div>
    )
}

export default ScrollCircle