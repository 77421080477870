import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

import { useState, useEffect } from 'react'
import axios from 'axios'
import { ApiUrl, BaseUrl } from '../Component/utils/config'
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle'
import { Helmet } from 'react-helmet'
const Partners = () => {
  const [PartnerIntro, setPartnerIntro] = useState([])
  const [AllCountry, setAllCountry] = useState([]);
  const [AllClient, setAllClient] = useState([]);
  const [AllMobClient, setAllMobClient] = useState([]);
  const [defaultCountry, setdefaultCountry] = useState('all');
  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getPartnerContent`);
        setPartnerIntro(response.data.PartnerContentDet);
        setAllCountry(response.data.BranchList)
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    fetchFilmList();
  }, []);
  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getAllClient`);
        setAllClient(response.data);
        setAllMobClient(response.data);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    fetchFilmList();
  }, []);
  const clientChange = async (value) => {
    try {
      const response = await axios.get(`${ApiUrl}/getCountrywiseClient`, {
        params: {
          data: value
        }
      });
      // console.log(response.data);
      setAllClient(response.data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  }
  const MobClientChange = async (value) => {
    try {
      const response = await axios.get(`${ApiUrl}/getCountrywiseClient`, {
        params: {
          data: value
        }
      });
      // console.log(response.data);
      setAllClient(response.data);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  }
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{PartnerIntro?.cms_meta_title}</title>
        <meta name="description" content={PartnerIntro?.cms_meta_description} />
        <link rel="canonical" href={PartnerIntro?.CanonicalTag} />
        <meta name='robots' content={`${PartnerIntro?.IndexValue == 1 ? ', index' : 'noindex'}, ${PartnerIntro?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
        {/* <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' /> */}
      </Helmet>
      <Header />
      <main className="main-content">

        <section className="inner-page-intro position-relative p-150 pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 ">
                <div className="inter-text-para">
                  <h1 className="text-white1">{PartnerIntro?.cms_short_title}</h1>
                  <h3>{PartnerIntro?.cms_short_Sub_title}</h3>
                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: PartnerIntro?.cms_long_title }} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="p-100 position-relative sub-section d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="main-tab-all sec-partners-inner">
                    <div className="sub-tab-all ">
                      <nav>
                        <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">

                          <button className="nav-link active" id="nav-Year-All-tab" data-bs-toggle="tab" data-bs-target="#nav-Year-All" type="button" role="tab" aria-controls="nav-Year-All" aria-selected="true" onClick={() => clientChange('all')}>All</button>
                          {AllCountry.map((Country, index) => {
                            return (
                              <button className="nav-link" id="nav-Year-2023-tab" data-bs-toggle="tab" data-bs-target="#nav-Year-2023" type="button" role="tab" aria-controls="nav-Year-2023" aria-selected="false" onClick={() => clientChange(Country?.BranchSlug)}>{Country?.BranchName}</button>
                            )
                          })}
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade active show" id="nav-Year-All" role="tabpanel" aria-labelledby="nav-Year-All-tab">
                          <div className="filmoList">
                            <ul>
                              {AllClient.map((Client, index) => {

                                return (
                                  <li key={index}><img src={`${BaseUrl}/client-image/${Client.ClientImage}`} alt={Client.ClientName} /></li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-100 pb-0 position-relative sub-section d-block d-lg-none">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="main-tab-all sec-partners-inner">
                  <div className="sub-tab-all sub-tab-all-mob">
                    <div className="accordion accordion-flush" id="accordionFlushPartners">
                      {/* ******* */}
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading-All">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-All" aria-expanded="false" aria-controls="flush-collapse-All">
                            All
                          </button>
                        </h2>
                        <div id="flush-collapse-All" className="accordion-collapse collapse" aria-labelledby="flush-heading-All" data-bs-parent="#accordionFlushPartners">
                          <div className="accordion-body">
                            <div className="filmoList">
                              <ul>
                                {AllMobClient.map((clientMob, index) => {
                                  return (
                                    <li key={index}><img src={`${BaseUrl}/client-image/${clientMob.ClientImage}`} alt={clientMob.ClientName} /></li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* ********* */}
                      {AllCountry.map((countryMob, index) => {
                        var btnID = countryMob?.BranchSlug + 'IDDDD';
                        var bodyID = countryMob?.BranchSlug + 'bodyIDD';
                        return (
                          <div className="accordion-item">
                            <h2 className="accordion-header" id={btnID}>
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${bodyID}`} aria-expanded="false" aria-controls={bodyID} onClick={() => MobClientChange(countryMob.BranchSlug)}>
                                {countryMob.BranchName}
                              </button>
                            </h2>
                            <div id={bodyID} className="accordion-collapse collapse" aria-labelledby={btnID} data-bs-parent="#accordionFlushPartners">
                              <div className="accordion-body">
                                <div className="filmoList">
                                  <ul>
                                    {AllClient.map((clientLMobb, index) => {
                                      return (
                                        <li key={index}><img src={`${BaseUrl}/client-image/${clientLMobb.ClientImage}`} alt={clientLMobb?.ClientName} /></li>
                                      )
                                    })}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      {/* <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading-Asia">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-Asia" aria-expanded="false" aria-controls="flush-collapse-Asia">
                            Asia
                          </button>
                        </h2>
                        <div id="flush-collapse-Asia" className="accordion-collapse collapse" aria-labelledby="flush-heading-Asia" data-bs-parent="#accordionFlushPartners">
                          <div className="accordion-body">
                            <div className="filmoList">
                              <ul>
                                <li><img src="assets/images/inner-partners/01.png" /></li>
                                <li><img src="assets/images/inner-partners/02.png" /></li>
                                <li><img src="assets/images/inner-partners/03.png" /></li>
                                <li><img src="assets/images/inner-partners/04.png" /></li>
                                <li><img src="assets/images/inner-partners/05.png" /></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-heading-Africa">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse-Africa" aria-expanded="false" aria-controls="flush-collapse-Africa">
                            Africa
                          </button>
                        </h2>
                        <div id="flush-collapse-Africa" className="accordion-collapse collapse" aria-labelledby="flush-heading-Africa" data-bs-parent="#accordionFlushPartners">
                          <div className="accordion-body">
                            <div className="filmoList">
                              <ul>
                                <li><img src="assets/images/inner-partners/06.png" /></li>
                                <li><img src="assets/images/inner-partners/07.png" /></li>
                                <li><img src="assets/images/inner-partners/08.png" /></li>
                                <li><img src="assets/images/inner-partners/09.png" /></li>
                                <li><img src="assets/images/inner-partners/10.png" /></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

      </main>
      <ScrollCircle />
      <Footer />
    </>
  )
}

export default Partners