export const filmographySlider = {
    300: {
        slidesPerView: 2,
        spaceBetween: 24,
        resistanceRatio: 0.85
    },
    768: {
        slidesPerView: 3,
        spaceBetween: 24,
        resistanceRatio: 0.85
    },
    1400: {
        slidesPerView: 4,
        spaceBetween: 24,
        resistanceRatio: 0.85
    }
};

export const mediaSlider = {
    300: {
      slidesPerView: 1,
      spaceBetween: 24,
      resistanceRatio: 0.85
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 24,
      resistanceRatio: 0.85
    },
    1400: {
      slidesPerView: 3,
      spaceBetween: 50,
      resistanceRatio: 0.85
    }
}
