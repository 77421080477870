import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { Helmet } from 'react-helmet'
import AboutIntro from '../Component/AboutComponent/AboutIntro'
import AboutWhyMe from '../Component/AboutComponent/AboutWhyMe'
import VfxPartner from '../Component/VfxComponent/VfxPartner'
import Journey from '../Component/HomeComponent/Journey'
import { useEffect,useState } from 'react'
import axios from 'axios'
import { ApiUrl,BaseUrl } from '../Component/utils/config'
import ScrollCircle from '../Component/Common/ScrollCircle'
import { scroll } from "framer-motion/dom";
const About = () => {
  const [AllAboutContent, setAllAboutContent] = useState([]);
    useEffect(() => {
        const HomeAboutIntroData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getAboutCmsContent`);
                setAllAboutContent(response.data);
            } catch (error) {
                console.error("Error fetching about data:", error);
            }
        };
        HomeAboutIntroData();
    }, []);
    useEffect(() => {
      const progressWheel = document.querySelector(".progress");
      scroll((progress) => {
          progressWheel.style.strokeDasharray = `${progress}, 1`;
      });
  }, []);
  return (
    <>
      <Header />
      <Helmet>
            <title>{AllAboutContent.AboutIntroDet?.cms_meta_title}</title>
            <meta name="description" content={AllAboutContent.AboutIntroDet?.cms_meta_description} />
            <link rel="canonical" href={AllAboutContent.AboutIntroDet?.CanonicalTag} />
            <meta name='robots' content={`${AllAboutContent.AboutIntroDet?.IndexValue == 1 ? ', index' : 'noindex'}, ${AllAboutContent.AboutIntroDet?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
            {/* <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' /> */}
        </Helmet>
      <main className="main-content">
        <VfxPartner data={AllAboutContent.VfxPartner} />
        <AboutIntro data={AllAboutContent.AboutIntroDet}/>
        <Journey data={AllAboutContent.JourneyDet}/>
        {/* <AboutWhyMe data={AllAboutContent.AboutWhyMeIntroDet}/> */}
      </main >
      <ScrollCircle/>
      <Footer />
    </>
  )
}

export default About