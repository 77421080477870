import React from 'react'

const Journey = ({data}) => {
    return (
        <section className="p-150 sec-vfx-journey d-none">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="inter-text-para text-center">
                            <h2><span>{data?.cms_short_title}</span> <label>{data?.cms_short_title}</label></h2>
                            <p className="text-white1"><div dangerouslySetInnerHTML={{ __html: data?.cms_long_title }} /></p>
                        </div>
                    </div>

                    <div className="col-lg-10 mx-auto">
                        <div className="about-video vfx-journey-vdo">
                            <img src="assets/images/about-vdo.jpg"/>
                                <img src="assets/images/play-vdo.png" className="vdo-play circle pulse"/>
                                </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Journey