import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../Component/utils/config';
import Swal from 'sweetalert2'
import ReCAPTCHA from 'react-google-recaptcha';
const Contact = () => {
  const [ContactIntro, setContactIntro] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [formData, setFormData] = useState({
    Name: '',
    Title: '',
    Company: '',
    Email: '',
    PhoneNo: '',
    MsgType: '',
    Msg: ''
  });
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  useEffect(() => {
    const ContactIntro = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getContactIntroDetails`);
        setContactIntro(response.data);
      } catch (error) {
        console.error("Error fetching about data:", error);
      }
    };
    ContactIntro();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.Name.trim()) {
      errors.Name = 'Name is required.';
    }
    if (!data.Title.trim()) {
      errors.Title = 'Title is required.';
    }
    if (!data.Company.trim()) {
      errors.Company = 'Company is required.';
    }
    if (!data.Email.trim()) {
      errors.Email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.Email.trim())) {
      errors.Email = 'Invalid email address';
    }
    /*
    if (!data.PhoneNo.trim()) {
      errors.PhoneNo = 'Phone number is required';
    } else if (!/^\d{10}$/.test(data.PhoneNo.trim())) {
      errors.PhoneNo = 'Invalid phone number';
    }
    */
    if (!formData.MsgType || formData.MsgType === '0') {
      errors.MsgType = "Please select your purpose.";
    }
    console.log(errors);
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm(formData);

    if (Object.keys(errors).length === 0 && recaptchaToken) {
    // if (Object.keys(errors).length === 0) {
      const ApiURL12345 = `${ApiUrl}/contactMail`;
      axios.post(ApiURL12345, formData)
        .then(response => {
          Swal.fire({
            icon: "success",
            title: "Thank you for getting in touch!",
            text: "Our team will reach out ASAP. Peace of mind is coming your way.",
            customClass: {
              popup: 'swal-custom-popup',
              title: 'swal-custom-title',
              content: 'swal-custom-content',
              footer: 'swal-custom-footer'
            },
            css: {
              popup: 'background-color: black;',
              title: 'color: yellow;',
              content: 'color: white;',
              footer: 'color: white;'
            },
            confirmButtonText: 'OK',
            confirmButtonColor: 'green',
            allowOutsideClick: false, // Prevents closing the dialog by clicking outside
            allowEscapeKey: false, // Prevents closing the dialog by pressing ESC key
            allowEnterKey: true, // Allows closing the dialog by pressing Enter key
            showCancelButton: false,
            focusConfirm: true,
            preConfirm: () => {
              // This function is called when the user clicks the "OK" button
              window.location.reload(); // Refresh the page
            }
          });


        })
        .catch(error => {
          console.error('Error occurred while sending data:', error);
        });
    } else {
      setErrors({ ...errors, recaptcha: 'Please complete the reCAPTCHA verification' });
    }
  };
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  return (
    <>
      <Header />
      <main class="main-content">

        <section class="inner-page-intro position-relative p-150 pb-0">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-10 ">
                <div class="inter-text-para">
                  <h1 class="text-white1">{ContactIntro?.cms_short_title}</h1>
                  <h3>{ContactIntro?.cms_short_Sub_title}</h3>
                  <div class="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: ContactIntro?.cms_long_title }} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="p-100  position-relative sub-section">
          <div class="container">
            {/* <div class="row">
              <div class="col-lg-12">
                <div class="contact-mail">
                  <h4>FOR ALL SALES INQUIRIES CONTACT</h4>
                  <a href="mailto:sales@yveslaniel.com">Email: sales@yveslaniel.com</a>
                </div>
              </div>
            </div> */}
            <div class="others-contact">
              <div class="row align-items-center">
                <div class="col-lg-8">
                  <div class="contact-mail">
                    <h4>Lets Connect</h4>
                    {/* <h4>FOR ALL SALES INQUIRIES CONTACT</h4> */}
                    {/* <a href="mailto:sales@yveslaniel.com">Email: sales@yveslaniel.com</a> */}
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="social-cont">
                    <div class="social-info">
                      <a href="https://www.linkedin.com/company/vfx-outsourcing-partner" target="_blank"><img src="assets/images/socials/01.png" /></a>
                      <a href="https://calendly.com/yves56/virtual-coffee-with-yves" target="_blank"><img src="assets/images/socials/04.png" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div class="contact-form">
                  <ul>
                    <li>
                      <div class="all-cont-info">
                        {/* <a href="javascript:void(0)">
                          <img src="assets/images/loc.png" />
                          <label>Level 13, 2 Elizabeth St, Melbourne, Victoria 3000, Australia</label>
                        </a> */}
                        <a href="tel:+15144645600">
                          <img src="assets/images/call.png" />
                          <label>+1 (514) 464-5600</label>
                        </a>
                        <a href="mailto:info@vfxoutsourcingpartner.com">
                          <img src="assets/images/mail.png" />
                          <label>info@vfxoutsourcingpartner.com</label>
                        </a>
                        <a href="javascript:void(0)">
                          <img src="assets/images/loc.png" />
                          <label>Montreal, QC, Canada</label>
                        </a>
                      </div>
                      {/* <div class="text-left">
                        <div class="btn-know-more main-btn">
                          <a href="javascript:void(0)">Find on Map</a>
                        </div>
                      </div> */}
                      <div class="social-cont">
                        <h5>Connect with us</h5>
                        <div class="social-info">
                          <a href="https://www.linkedin.com/company/vfx-outsourcing-partner" target="_blank"><img src="assets/images/socials/01.png" /></a>
                          <a href="https://calendly.com/yves56/virtual-coffee-with-yves" target="_blank"><img src="assets/images/socials/04.png" /></a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="contact-mail">
                        <h4>SEND A MESSAGE</h4>
                        <div class="contact-form-sec">
                          <div class="contact-action">
                            <form action="javascript:void(0)" method="post" onSubmit={handleSubmit}>
                              <div class="row">
                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group contact-sec">
                                    <input type="text" name="Name" id="Name" placeholder="Name" class="form-control" value={formData.Name} onChange={handleChange} />
                                    {errors.Name && <div style={{ color: 'red', fontSize: '12px' }}>{errors.Name}</div>}
                                    {/* <div style={{ color: 'red', fontSize: '12px' }} id="errName"></div> */}
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group contact-sec">
                                    <input type="text" name="Title" id="Title" placeholder="Title" class="form-control" value={formData.Title} onChange={handleChange} />
                                    {errors.Title && <div style={{ color: 'red', fontSize: '12px' }}>{errors.Title}</div>}
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group contact-sec">
                                    <input type="text" name="Company" id="Company" placeholder="Company" class="form-control" value={formData.Company} onChange={handleChange} />
                                    {errors.Company && <div style={{ color: 'red', fontSize: '12px' }}>{errors.Company}</div>}
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group contact-sec">
                                    <input type="text" name="Email" id="Email" placeholder="Email" class="form-control" value={formData.Email} onChange={handleChange} />
                                    {errors.Email && <div style={{ color: 'red', fontSize: '12px' }}>{errors.Email}</div>}
                                  </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                  <div class="form-group contact-sec">
                                    <input type="hidden" name="country_code" id="countryCode" />
                                    <input type="tel" inputmode="numeric" pattern="[0-9]*" name="PhoneNo" id="PhoneNo" placeholder="Phone" value={formData.PhoneNo} onChange={handleChange} />
                                    {/* {errors.PhoneNo && <div style={{ color: 'red',fontSize:'12px' }}>{errors.PhoneNo}</div>} */}
                                  </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                  <div class="form-group contact-sec">
                                    <select name="MsgType" id="MsgType" class="form-control form-select" value={formData.MsgType} onChange={handleChange}>
                                      <option value="0">Choose an option  </option>
                                      <option value="I need outsourcing NOW">I need outsourcing NOW  </option>
                                      <option value="I want information about outsourcing possibilities">I want information about outsourcing possibilities </option>
                                      <option value="I want to be a vendor">I want to be a vendor  </option>
                                      <option value="Other">Other </option>
                                    </select>
                                    {errors.MsgType && <div style={{ color: 'red', fontSize: '12px' }}>{errors.MsgType}</div>}
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                  <div class="form-group contact-sec">
                                    <textarea name="Msg" id="Msg" placeholder="Message" class="form-control" value={formData.Msg} onChange={handleChange}></textarea>
                                  </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                  <div class="form-group contact-sec">
                                    <ReCAPTCHA sitekey="6LfD1LwpAAAAAIz9qAxyINVsIxgkYHC1jmXBYNlh" onChange={handleRecaptchaChange} />
                                    {errors.recaptcha && <div className="errorBox" style={{ color: 'red' }}>{errors.recaptcha}</div>}
                                  </div>
                                </div>
                                <div class="col-lg-12 subhold text-right">
                                  <div class="btn-know-more main-btn">
                                    <button type="submit" value="SUBMIT">Submit</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ScrollCircle />
      <Footer />
    </>
  )
}

export default Contact