import React from 'react'
import quoteBif from '../../assets/images/big-quote.png'
import quoteSmall from '../../assets/images/small-quote.png'
// 
const VfxPartner = ({data}) => {
    return (
        <section className="p-150 position-relative sub-section sec-why-vfx-outsource">
                <div className="container">
                    <ul>
                        <li>
                            <div className="inter-text-para">
                                <h2><span>{data?.cms_short_title}<br />{data?.cms_short_Sub_title}</span></h2>
                                <div className="intro-page-para position-relative">
                                    <img src={quoteBif} className='found-bg-q' />
                                    <p><div dangerouslySetInnerHTML={{ __html: data?.cms_long_title }} /></p>
                                    <img src={quoteSmall} className='found-small-q'/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <img src="assets/images/vfx-outsource.png" className="img-vfx-outsource"/>
                        </li>
                    </ul>
                </div>
            </section>
    )
}

export default VfxPartner