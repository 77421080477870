import React from 'react'
import { BaseUrl } from '../utils/config'
// import linkIcon from '../assets/images/socials/01.png';
import linkIcon from '../../assets/images/socials/01.png';
const TeamCard = ({ data }) => {
    return (
        <ul>
            <li>
                <img src={`${BaseUrl}/Leadership_image/${data?.team_image}`} />
            </li>
            <li>
                <div className="team-info intro-page-para">
                    <h3>{data?.team_name}</h3>
                    <h4>{data?.team_designation}</h4>
                    {data?.teamEmailID && data?.teamEmailID !== '-' && (
                        <label><a href={`mailto:${data?.teamEmailID}`}>{data?.teamEmailID}</a></label>
                    )}
                    <p className="text-white1"><div dangerouslySetInnerHTML={{ __html: data?.team_content }} /></p>
                    {/* <a href={data?.team_social}><img src={linkIcon} className = 'linkIconTeam'></img></a> */}
                    {data?.team_social !== '#' && (
                        <a href={data?.team_social}>
                            <img src={linkIcon} className='linkIconTeam' alt='Link Icon' />
                        </a>
                    )}

                </div>
            </li>
        </ul>
    )
}

export default TeamCard