import React from 'react'

const AboutIntro = ({data}) => {
  return (
    <section className="inner-page-intro position-relative p-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 ">
                <div className="inter-text-para">
                  <h1 className="text-white1">{data?.cms_short_title}</h1>
                  <h3>{data?.cms_short_Sub_title}</h3>
                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: data?.cms_long_title }} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default AboutIntro