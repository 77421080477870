import React, { useState, useEffect } from 'react';

const Cookies = () => {
    const [showConsent, setShowConsent] = useState(true);

    const closeCookieConsent = () => {
        setShowConsent(false);
        document.cookie = "cookiesAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
    };
    const closeCookieConsentone = () => {
        setShowConsent(false);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1); // Set expiration to one day from now
        document.cookie = `cookiesAccepted=true; expires=${expirationDate.toUTCString()}; path=/`;
    };
    
    useEffect(() => {
        // Check if cookies have been accepted before
        if (document.cookie.indexOf("cookiesAccepted=true") !== -1) {
            setShowConsent(false);
        }
    }, []);

    return (
        // <>
        //     {showConsent && (
        //         <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#000', padding: '10px', textAlign: 'center', color: '#fff' }}>
        //             <p>This website uses cookies to ensure you get the best experience on our website.</p>
        //             {/* <button onClick={closeCookieConsent}>Close</button> */}
        //             {/* <button onClick={closeCookieConsent}>OK</button> */}
        //             <div class="btn-know-more main-btn"><button onClick={closeCookieConsent}>Ok</button></div>
        //         </div>
        //     )}
        // </>
        <>
            {showConsent && (
                <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', backgroundColor: '#000', padding: '10px', textAlign: 'center', color: '#fff', display:'flex', justifyContent:'center' }}>
                    <span style={{padding:'10px'}}>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</span>
                    <span style={{ cursor: 'pointer', position: 'absolute', top: '5px', right: '10px', fontSize: '20px' }} onClick={closeCookieConsentone}>&times;</span>
                    {/* <button onClick={closeCookieConsent}>OK</button> */}
                    <div class="btn-know-more main-btn"><button onClick={closeCookieConsent}>Ok</button></div>
                </div>
            )}
        </>
    );
};

export default Cookies;
