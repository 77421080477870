import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../Component/utils/config';
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle'
const Media = () => {
  const [MediaContent, setMediaContent] = useState([]);
  const [AllMedia, setAllMedia] = useState([]);
  // const [MainMedia, setMainMedia] = useState('events');
  const [SearchButtoName, setSearchButtoName] = useState(false)
  const [formData, setFormData] = useState({ SearchString: '' });
  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getMediaContent`);
        setMediaContent(response.data);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBannerData();
  }, []);
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  // const mediaChange = (value) => {
  //   setMainMedia(value);
  // }
  useEffect(() => {
    const getMediaList = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getAllMediaList`);
        setAllMedia(response.data);
        setSearchButtoName(false);
      } catch (error) {
        console.error("Error fetching Media List:", error);
      }
    };
    getMediaList();
  },[]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = () => {
    const ApiURL12345 = `${ApiUrl}/searchMedia`;
    axios.post(ApiURL12345, formData)
      .then(response => {
        setAllMedia(response.data);
        setSearchButtoName(true);
      })
      .catch(error => {
        console.error('Error occurred while sending data:', error);
      });
  };
  const searchClear = () => {
    const ApiURL12345 = `${ApiUrl}/AllMediaListTogether`;
    axios.get(ApiURL12345)
      .then(response => {
        setAllMedia(response.data);
        setSearchButtoName(false);
        setFormData({ ...formData, SearchString: '' });
      })
      .catch(error => {
        console.error('Error occurred while sending data:', error);
      });
  }
  return (
    <>
      <Header />
      <Helmet>
        <title>{MediaContent?.cms_meta_title}</title>
        <meta name="description" content={MediaContent?.cms_meta_description} />
        <link rel="canonical" href={MediaContent?.CanonicalTag} />
        <meta name='robots' content={`${MediaContent?.IndexValue == 1 ? ', index' : 'noindex'}, ${MediaContent?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
      </Helmet>
      <main className="main-content">

        <section className="inner-page-intro position-relative p-150 pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 ">
                <div className="inter-text-para">
                  <h1 className="text-white1">{MediaContent?.cms_short_title}</h1>
                  <h3>{MediaContent?.cms_short_Sub_title}</h3>
                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: MediaContent?.cms_long_title }} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="position-relative sub-section all-media-list p-100 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <form action='javascript:void(0)' method="post" onSubmit={(e) => e.preventDefault()} >
                  <div className="row">
                    <div className="col-lg-6">
                      <div class="form-group contact-sec">
                        <input type="text" name="SearchString" id="SearchString" placeholder="Search hear." class="form-control" value={formData.SearchString} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div class="btn-know-more main-btn">
                        {!SearchButtoName ? (
                          <button type="submit" onClick={() => handleSubmit()}>Submit</button>
                        ) : (
                          <button type="submit" onClick={() => searchClear()}>Clear</button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {AllMedia.length > 0 ? (
            <div className="container">
              {AllMedia.map((media, index) => {
                const originalDateStr = media?.NewsDate;
                const originalDate = new Date(originalDateStr);
                const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                const monthAbbreviation = months[originalDate.getMonth()];
                const formattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;

                return (
                  <div className="row" key={index}>
                    <div className="col-lg-12">
                      <div className="media-info">
                        <Link to={`/media/${media?.MetaUrl}`}>
                          <ul>
                            <li>
                              <img src={`${BaseUrl}/blog-image/${media?.NewsImage}`} />
                            </li>
                            <li>
                              <div className="intro-page-para">
                                <h3>{media?.NewsTitle}</h3>
                                <p><div dangerouslySetInnerHTML={{ __html: media?.NewsShortDescription }} /></p>
                                <div className="media-loc-date">
                                  <span><i className="fa-solid fa-calendar-days"></i> {formattedDate}</span>
                                  {media?.NewsLocation && (
                                    <span><i className="fa-solid fa-location-dot"></i> {media?.NewsLocation}</span>
                                  )}
                                </div>
                              </div>
                              <div className="text-left">
                                <div className="btn-know-more main-btn">
                                  <span className="mediaBtn">More</span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>
          ) : (
            <div className="container">
              <p style={{ color: '#fff' }}>No result found.</p>
            </div>
          )}

        </section>

      </main>
      <ScrollCircle />
      <Footer />
    </>
  )
}

export default Media