// export const ApiUrl = "https://yves.brandwizz.in/main-admin/user";
// export const ApiUrl = "http://localhost/react/yves/main-admin/user/";

// export const BaseUrl = "https://yves.brandwizz.in/main-admin/assets/upload";



export const ApiUrl = "https://beta.vfxoutsourcingpartner.com/main-admin/user";
export const BaseUrl = "https://beta.vfxoutsourcingpartner.com/main-admin/assets/upload";


// export const ApiUrl = "https://vfxoutsourcingpartner.com/main-admin/user";
// export const BaseUrl = "https://vfxoutsourcingpartner.com/main-admin/assets/upload";