import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { mediaSlider } from '../../utils/SwiperBreakpoint.js'

import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../utils/config.js';
const Media = ({ data }) => {
    const [MediaList, setMediaList] = useState([]);
    useEffect(() => {
        const fetchMediaList = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getMediaList`);
                setMediaList(response.data);
            } catch (error) {
                console.error("Error fetching Media List:", error);
            }
        };
        fetchMediaList();
    }, []);
    return (
        <section className="p-150 sec-latest-media">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 mx-auto">
                        <div className="inter-text-para text-center">
                            <h2><span>{data?.cms_short_title}</span> <label>{data?.cms_short_title}</label></h2>
                            <p><div dangerouslySetInnerHTML={{ __html: data?.cms_long_title }} /></p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="vfx-filmography-list">
                            <Swiper className="swiper mySwiper mediaSlider" loop={true} modules={[Pagination]} pagination={{ clickable: true }} breakpoints={mediaSlider}>
                                <div className="swiper-wrapper">
                                    {MediaList.map((media, index) => {
                                        const originalDateStr = media?.NewsDate;
                                        const originalDate = new Date(originalDateStr);
                                        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
                                        const monthAbbreviation = months[originalDate.getMonth()];
                                        const formattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;
                                        return (
                                            <SwiperSlide className="swiper-slide" key={index}>
                                                <div className="media-info hm-media-info">
                                                    <Link to={`/media/${media.MetaUrl}`}>
                                                        <img src={`${BaseUrl}/blog-image/${media?.NewsImage}`} alt="media image" />
                                                        <div className="media-txt">
                                                            <span>{formattedDate}</span>
                                                            <h3>{media?.NewsTitle}</h3>
                                                            <div className="btn-media"> More</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                                </div>
                                {/* <div className="swiper-pagination"></div> */}
                            </Swiper>
                        </div>
                        <div className="text-center">
                            <div className="btn-know-more main-btn">
                                <Link to="/media">More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Media