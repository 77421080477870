import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { ApiUrl, BaseUrl } from '../Component/utils/config';
import { Helmet } from 'react-helmet';
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle'
const Filmography = () => {
  const [filmographyContent, setFilmographyContent] = useState([]);
  const [allSubCategoryList, setAllSubCategoryList] = useState([]);
  const [AllFilmographyList, setAllFilmographyList] = useState([]);
  const [defaultCategory, setDefaultCategory] = useState('year');
  const [defaultSubCategory, setdefaultSubCategory] = useState('all');
  const [AllYearMob, setAllYearMob] = useState([]);
  const [AllGenresMob, setAllGenresMob] = useState([]);
  const [AllTypeMob, setAllTypeMob] = useState([]);
  useEffect(() => {
    const fetchFilmographyData = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getFilmographyData`);
        setFilmographyContent(response.data.MediaCont);
        setAllSubCategoryList(response.data.AllYear);
        setAllFilmographyList(response.data.AllFilm);
        setAllYearMob(response.data.AllYearMob);
        setAllGenresMob(response.data.AllGenresMob);
        setAllTypeMob(response.data.AllTypeMob);
      } catch (error) {
        console.error("Error fetching filmography data:", error);
      }
    };
    fetchFilmographyData();
  }, []);

  const handleCategoryChange = async (category) => {
    setDefaultCategory(category);
    try {
      const response = await axios.get(`${ApiUrl}/getSubCategory?data=${category}`);
      setAllSubCategoryList(response.data.SubCategoryList);
      setAllFilmographyList(response.data.AllFilm);
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
    }
  }
  const handleFilmSelect = async (value) => {
    setdefaultSubCategory(value);
    try {
      const response = await axios.get(`${ApiUrl}/getFilmFilter`, {
        params: {
          Category: defaultCategory,
          SubCategory: defaultSubCategory
        }
      });
      setAllFilmographyList(response.data);
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
    }
  }
  const GetFilmMob = async (value) => {
    setdefaultSubCategory(value);
    try {
      const response = await axios.get(`${ApiUrl}/getFilmFilter`, {
        params: {
          Category: defaultCategory,
          SubCategory: defaultSubCategory
        }
      });
      setAllFilmographyList(response.data);
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
    }
  }
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>{filmographyContent?.cms_meta_title}</title>
        <meta name="description" content={filmographyContent?.cms_meta_description} />
        <link rel="canonical" href={filmographyContent?.CanonicalTag} />
        <meta name='robots' content={`${filmographyContent?.IndexValue == 1 ? ', index' : 'noindex'}, ${filmographyContent?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
        {/* <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' /> */}
      </Helmet>
      <Header />
      <main className="main-content">
        <section className="inner-page-intro position-relative p-150 pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="inter-text-para">
                  <h1 className="text-white1">{filmographyContent?.cms_short_title}</h1>
                  <h3>{filmographyContent?.cms_short_Sub_title}</h3>
                  <div className="intro-page-para">
                    <p dangerouslySetInnerHTML={{ __html: filmographyContent?.cms_long_title }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="p-100 position-relative sub-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="main-tab-all">
                    <nav>
                      <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                        <button className={`nav-link ${defaultCategory === 'year' ? 'active' : ''}`} onClick={() => handleCategoryChange('year')}>Year</button>
                        <button className={`nav-link ${defaultCategory === 'genres' ? 'active' : ''}`} onClick={() => handleCategoryChange('genres')}>Genres</button>
                        <button className={`nav-link ${defaultCategory === 'type' ? 'active' : ''}`} onClick={() => handleCategoryChange('type')}>Type</button>
                      </div>
                    </nav>
                    <div className="tab-content d-none d-lg-block" id="nav-tabContent">
                      <div className="tab-pane fade active show" id="nav-Year" role="tabpanel" aria-labelledby="nav-Year-tab">
                        <div className="sub-tab-all d-none d-lg-block">
                          <nav>
                            <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                              <button className={`nav-link ${defaultSubCategory === 'all' ? 'active' : ''}`} onClick={() => handleFilmSelect('all')}>All</button>
                              {allSubCategoryList.map((subCat, index) => {
                                const subCategoryName = defaultCategory === 'year' ? subCat?.Year : (defaultCategory === 'genres' ? subCat?.GenresName : subCat?.TypeName);
                                return (
                                  <button key={index} className={`nav-link ${defaultSubCategory === subCategoryName ? 'active' : ''}`} onClick={() => handleFilmSelect(subCategoryName)}>{subCategoryName}</button>
                                )
                              })}
                            </div>
                          </nav>
                          <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade active show" id="nav-Year-All" role="tabpanel" aria-labelledby="nav-Year-All-tab">
                              <div className="filmoList">
                                {AllFilmographyList.length > 0 ? (
                                  <ul>
                                    {AllFilmographyList.map((filmList, index) => (
                                      <li key={index}><img src={`${BaseUrl}/filmography/${filmList?.ProjectImage}`} alt={filmList?.ProjectName} /></li>
                                    ))}
                                  </ul>
                                ) : (
                                  <p>No results to show.</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ********************** */}
                    <div className="tab-content d-block d-lg-none" id="nav-tabContent ">
                      <div className="tab-pane fade active show" id="nav-Year" role="tabpanel" aria-labelledby="nav-Year-tab">

                        <div className="sub-tab-all sub-tab-all-mob sub-filmo-mob">
                          {defaultCategory === 'year' && (
                            <div class="subcatDrop">
                              <select class="form-control form-select" onChange={(event) => GetFilmMob(event.target.value)}>
                                <option value="all" onClick={() => GetFilmMob('all')}>All</option>
                                {AllYearMob.map((YearMob, index) => {
                                  return (
                                    <option value={YearMob?.Year} key={index}>{YearMob?.Year}</option>
                                  )
                                })}
                              </select>
                            </div>
                          )}
                          {defaultCategory === 'genres' && (
                            <div class="subcatDrop">
                              <select class="form-control form-select" onChange={(event) => GetFilmMob(event.target.value)}>
                                <option value="all" onClick={() => GetFilmMob('all')}>All</option>
                                {AllGenresMob.map((GenresMob, index) => {
                                  return (
                                    <option value={GenresMob?.GenresName} key={index}>{GenresMob?.GenresName}</option>
                                  )
                                })}
                              </select>
                            </div>
                          )}
                          {defaultCategory === 'type' && (
                            <div class="subcatDrop">
                              <select class="form-control form-select" onChange={(event) => GetFilmMob(event.target.value)}>
                                <option value="all">All</option>
                                {AllTypeMob.map((TypeMob, index) => {
                                  return (
                                    <option value={TypeMob?.TypeName} key={index} >{TypeMob?.TypeName}</option>
                                  )
                                })}
                              </select>
                            </div>
                          )}

                          <div className="filmoList">
                            {AllFilmographyList.length > 0 ? (
                              <ul>
                                {AllFilmographyList.map((FilmMob, index) => {
                                  return (
                                    <li key={index}><img src={`${BaseUrl}/filmography/${FilmMob?.ProjectImage}`} alt={FilmMob?.ProjectName} /></li>
                                  )
                                })}

                              </ul>
                            ) : (
                              <p>No results to show.</p>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>

                    {/* ***************** */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ScrollCircle/>
      <Footer />
    </>
  )
}

export default Filmography;
