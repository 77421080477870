import React from 'react'
import {filmographySlider} from '../../utils/SwiperBreakpoint.js'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';
import { ApiUrl,BaseUrl } from '../utils/config.js';
const Filmography = ({data}) => {
    const [FlimList, setFlimList] = useState([]);
    useEffect(() => {
        const fetchFilmList = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getFilmographyList`);
                setFlimList(response.data);
            } catch (error) {
                console.error("Error fetching banner data:", error);
            }
        };
        
        fetchFilmList();
    }, []);
    return (
        <section className="p-150 pb-0 sec-filmography">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="inter-text-para text-center">
                            <h2><span>{data?.cms_short_title}</span> <label>{data?.cms_short_title}</label></h2>
                            <p><div dangerouslySetInnerHTML={{ __html: data?.cms_long_title }} /></p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="vfx-filmography-list">
                            <Swiper className="swiper mySwiper filmographySlider" loop={true} modules={[Pagination]} pagination={{ clickable: true }} breakpoints={filmographySlider}>
                                <div className="swiper-wrapper">
                                    {FlimList.map((film,index)=>{
                                        return (
                                            <SwiperSlide className="swiper-slide" key={index}>
                                                <img src={`${BaseUrl}/filmography/${film?.ProjectImage}`} alt={film?.ProjectName}/>
                                            </SwiperSlide>
                                        )
                                    })}
                                </div>

                                {/* <div className="swiper-pagination"></div> */}
                            </Swiper>
                        </div>

                        <div className="text-center">
                            <div className="btn-know-more main-btn">
                                <Link to="/filmography">More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Filmography