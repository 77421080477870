import {useEffect} from "react"
import {useLocation} from "react-router-dom"

export default function ScrollToTop(){
	const {pathname} = useLocation();
    console.log('Pathname changed:', pathname);
	
	useEffect(()=>{
		window.scrollTo(-100,-100);
	},[pathname]);	
	return null;
}