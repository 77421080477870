import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

import { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../Component/utils/config';
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle';
const Blog = () => {
  const [BlogContent, setBlogContent] = useState([]);
  const [AllBlog, setAllBlog] = useState([]);
  useEffect(() => {
    const fetchBlogContent = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getBlogContent`);
        setBlogContent(response.data);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    fetchBlogContent();
  }, []);
  useEffect(() => {
    const fetchBlogList = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getAllBlog`);
        setAllBlog(response.data);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };
    fetchBlogList();
  }, []);
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  return (
    <>
      <Header />
      {/* <Helmet>
          <title>{AllAboutContent.AboutIntroDet?.cms_meta_title}</title>
          <meta name="description" content={AllAboutContent.AboutIntroDet?.cms_meta_description} />
          <link rel="canonical" href={AllAboutContent.AboutIntroDet?.CanonicalTag} />
          <meta name='robots' content={`${AllAboutContent.AboutIntroDet?.IndexValue == 1 ? ', index' : 'noindex'}, ${AllAboutContent.AboutIntroDet?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
      </Helmet> */}
      <main className="main-content">
        <section className="inner-page-intro position-relative p-150 pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-10 ">
                <div className="inter-text-para">
                  <h1 className="text-white1">{BlogContent?.cms_short_title}</h1>
                  <h3>{BlogContent?.cms_short_Sub_title}</h3>
                  <div className="intro-page-para">
                    <p><div dangerouslySetInnerHTML={{ __html: BlogContent?.cms_long_title }} /></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="position-relative sub-section all-media-list">
          <div className="container">
            {AllBlog.map((blog, index) => {
              const originalDateStr = blog?.NewsDate;
              const originalDate = new Date(originalDateStr);
              const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
              const monthAbbreviation = months[originalDate.getMonth()];
              const formattedDate = `${monthAbbreviation} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;

              return (
                <div className="row" key={index}>
                  <div className="col-lg-12">
                    <div className="media-info">
                      <ul>
                        <li>
                          <img src={`${BaseUrl}/blog-image/${blog?.NewsImage}`} />
                        </li>
                        <li>
                          <div className="intro-page-para">
                            <h3>{blog?.NewsTitle}</h3>
                            <p><div dangerouslySetInnerHTML={{ __html: blog?.NewsShortDescription }} /></p>
                            <div className="media-loc-date">
                              <span><i className="fa-solid fa-calendar-days"></i> {formattedDate}</span>
                              <span><i className="fa-solid fa-location-dot"></i> {blog?.NewsLocation}</span>
                            </div>
                          </div>
                          <div className="text-left">
                            <div className="btn-know-more main-btn">
                              <Link to={`/blog/${blog?.MetaUrl}`}>Know More</Link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )
            })}

          </div>
        </section>
      </main>
      <ScrollCircle />
      <Footer />
    </>
  )
}

export default Blog