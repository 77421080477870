import React from 'react'
import { Helmet } from 'react-helmet';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import HomeBanner from '../Component/HomeComponent/HomeBanner';
import ClientMarkque from '../Component/HomeComponent/ClientMarkque';
import VfxoutSourcing from '../Component/HomeComponent/VfxoutSourcing';
import Filmography from '../Component/HomeComponent/Filmography';
import Journey from '../Component/HomeComponent/Journey';
import LetConnect from '../Component/Common/LetConnect';
import Media from '../Component/Common/Media';
import BlogHome from '../Component/HomeComponent/BlogHome';
import { scroll } from "framer-motion/dom";
import BigQuote from '../assets/images/big-quote.png';
import SmallQuote from '../assets/images/small-quote.png';
import ScrollCircle from '../Component/Common/ScrollCircle';
import { useEffect,useState } from 'react';
import { ApiUrl,BaseUrl } from '../Component/utils/config';



import axios from 'axios';
const Home = () => {
    const [AboutContent, setAboutContent] = useState([]);
    const [AllCmsIntro, setAllCmsIntro] = useState([]);
    useEffect(() => {
        const HomeAboutIntroData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getHomeAboutIntro`);
                setAboutContent(response.data);
            } catch (error) {
                console.error("Error fetching about data:", error);
            }
        };
        HomeAboutIntroData();
    }, []);
    useEffect(() => {
        const fetchCmsData = async () => {
            try {
                const response = await axios.get(`${ApiUrl}/getAllHomeCmsData`);
                setAllCmsIntro(response.data);
            } catch (error) {
                console.error("Error fetching about data:", error);
            }
        };
        fetchCmsData();
    }, []);
    useEffect(() => {
        const progressWheel = document.querySelector(".progress");
        scroll((progress) => {
            progressWheel.style.strokeDasharray = `${progress}, 1`;
        });
    }, []);
    return (
        
        <>
            <Header />
            <Helmet>
                <title>{AboutContent?.cms_meta_title}</title>
                <meta name="description" content={AboutContent?.cms_meta_description} />
                <link rel="canonical" href={AboutContent?.CanonicalTag} />
                <meta name='robots' content={`${AboutContent?.IndexValue == 1 ? ', index' : 'noindex'}, ${AboutContent?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
                {/* <meta name='robots' content='index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' /> */}
            </Helmet>
            <main className="main-content">
                <HomeBanner />
                <ClientMarkque />
                {/* <section className="p-100 sec-lead-quote" style={{ backgroundImage: "url('assets/images/bg-lead-quote.jpg')" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 mx-auto">

                                <div className="lead-quote text-center">
                                    <div className="text-left"><img src={BigQuote} className="big-quote" /></div>
                                    <p className="text-white1"><div dangerouslySetInnerHTML={{ __html: AboutContent?.cms_long_title }} /></p>
                                    <div className="text-right"><img src={SmallQuote} className="small-quote" />
                                    </div>
                                    <h4>{AboutContent?.cms_short_title}</h4>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> */}
                <VfxoutSourcing data={AllCmsIntro.VfxOutDet}/>
                <Filmography data={AllCmsIntro.FilmographyDet}/>
                {/* <Journey data={AllCmsIntro.JourneyDet}/> */}
                {/* <LetConnect data={AllCmsIntro.getINtouchDet}/> */}
                <Media data={AllCmsIntro.mediaDet}/>
                {/* <BlogHome data={AllCmsIntro.BlogDet}/> */}
            </main>
            <ScrollCircle/>
            <Footer/>
            {/* <div>Home</div> */}
        </>
    )
}

export default Home