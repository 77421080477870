import React from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'

import TeamIntro from '../Component/TeamComponent/TeamIntro';
import TeamCard from '../Component/TeamComponent/TeamCard';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiUrl, BaseUrl } from '../Component/utils/config';
import { scroll } from "framer-motion/dom";
import ScrollCircle from '../Component/Common/ScrollCircle'
import { Helmet } from 'react-helmet';

const Team = () => {
  const [AllTeamData, setAllTeamData] = useState([]);
  const [TeamList, setTeamList] = useState([]);
  useEffect(() => {
    const fetchFilmList = async () => {
      try {
        const response = await axios.get(`${ApiUrl}/getAllTeamData`);
        setAllTeamData(response.data);
        setTeamList(response.data.AllTeam);
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchFilmList();
  }, []);
  useEffect(() => {
    const progressWheel = document.querySelector(".progress");
    scroll((progress) => {
      progressWheel.style.strokeDasharray = `${progress}, 1`;
    });
  }, []);
  return (
    <>
      <Helmet>
          <title>{AllTeamData.TeamContentDet?.cms_meta_title}</title>
          <meta name="description" content={AllTeamData.TeamContentDet?.cms_meta_description} />
          <link rel="canonical" href={AllTeamData.TeamContentDet?.CanonicalTag} />
          <meta name='robots' content={`${AllTeamData.TeamContentDet?.IndexValue == 1 ? ', index' : 'noindex'}, ${AllTeamData.TeamContentDet?.FollowValue == 1 ? ', follow' : 'nofollow'}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`} />
      </Helmet>
      <Header />
      <main className="main-content">
        <TeamIntro data={AllTeamData.TeamContentDet} />


        <section className="p-100 pb-0 position-relative sub-section sec-team-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="inter-text-para">
                  <h3 className="text-white1">MASTERS OF VFX</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="team-all">
                  {TeamList.map((team, index) => {
                    return (
                      <TeamCard data={team} />
                    )
                  })}

                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ScrollCircle />
      <Footer />
    </>
  )
}

export default Team